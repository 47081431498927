import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { ActionIcon, Button, Group, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconPencil, IconPlus, IconTrash, IconX } from '@tabler/icons-react';

import {
  Brand,
  GetBrandsRequestParams,
  GetBrandsSortBy,
  useCountBrandsQuery,
  useDeleteBrandMutation,
  useGetBrandsQuery,
} from '@api/brands.api';
import { isApiError } from '@api/index';

import SearchBar from '@components/SearchBar';
import UpsertBrandForm, {
  UPSERT_BRAND_FORM_MODAL_ID,
} from '@components/UpsertBrandForm';
import { LayoutContextType } from '@components/layout/Layout';

export default function Brands() {
  // ==========================================================================
  // State
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<GetBrandsRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 10),
    searchQuery: searchParams.get('search') || undefined,
    sortBy: 'name',
    sortOrder: 'asc',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Brand>>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as GetBrandsSortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: brandsCount = { count: 0 }, isLoading: isLoadingCount } =
    useCountBrandsQuery({ searchQuery });

  const { data = [], isLoading } = useGetBrandsQuery({
    ...filters,
    searchQuery,
  });

  const [deleteBrand] = useDeleteBrandMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateBrandClick = () => {
    openModal({
      modalId: UPSERT_BRAND_FORM_MODAL_ID,
      title: 'Crea marca',
      children: <UpsertBrandForm />,
    });
  };

  const handleEditBrandClick = (brand: Brand) => {
    openModal({
      modalId: UPSERT_BRAND_FORM_MODAL_ID,
      title: 'Modifica marca',
      children: <UpsertBrandForm brand={brand} />,
    });
  };

  const handleDeleteBrandClick = (name: string) => {
    openConfirmModal({
      title: 'Elimina marca',
      children: 'Stai per eliminare la marca.\nContinuare?',
      confirmProps: { color: 'red' },
      labels: { confirm: 'Conferma', cancel: 'Annulla' },
      onConfirm: async () => {
        try {
          await deleteBrand(name).unwrap();

          showNotification({
            title: 'Marca eliminata',
            message: 'Marca eliminata con successo',
          });
        } catch (e) {
          if (isApiError(e)) {
            showNotification({
              title: 'Errore',
              message: e.data.message,
              color: 'red',
              icon: <IconX />,
            });
          } else {
            showNotification({
              title: 'Errore',
              message: 'Errore sconosciuto',
              color: 'red',
              icon: <IconX />,
            });
            console.error(e);
          }
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useEffect(() => {
    setLayoutProps({ title: 'Marchi' });
  }, [setLayoutProps]);

  return (
    <>
      <Group mb="lg" justify="space-between">
        <Group>
          <SearchBar
            placeholder="Ricerca per nome"
            value={filters.searchQuery}
            onChange={(value) => setFilters({ ...filters, searchQuery: value })}
          />
        </Group>
        <Button onClick={handleCreateBrandClick} leftSection={<IconPlus />}>
          Crea marca
        </Button>
      </Group>
      <DataTable
        /* General */
        striped
        noRecordsText="Nessuna marca trovata"
        /* Data */
        fetching={isLoading || isLoadingCount}
        idAccessor="name"
        columns={[
          {
            accessor: 'name',
            title: 'Nome',
            sortable: true,
          },
          {
            accessor: 'actions',
            title: '',
            textAlign: 'right',
            width: '0%',
            render: (record) => (
              <Group wrap="nowrap" gap="xs">
                <Tooltip label="Modifica marca">
                  <ActionIcon
                    variant="light"
                    onClick={() => handleEditBrandClick(record)}
                  >
                    <IconPencil />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Elimina marca">
                  <ActionIcon
                    color="red"
                    variant="light"
                    onClick={() => handleDeleteBrandClick(record.name)}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        records={data}
        /* Sorting */
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        /* Pagination */
        page={filters.page!}
        recordsPerPage={filters.pageLength!}
        totalRecords={brandsCount.count}
        paginationText={() => ''}
        recordsPerPageLabel=""
        recordsPerPageOptions={[10, 20, 50]}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onRecordsPerPageChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
