import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { ActionIcon, Button, Group, Stack, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconCircleCheck,
  IconCircleX,
  IconInfoSquareRounded,
  IconPencil,
  IconPlus,
  IconTrash,
} from '@tabler/icons-react';

import {
  ErrorCode,
  GetErrorCodesRequestParams,
  GetErrorCodesSortBy,
  useCountErrorCodesQuery,
  useDeleteErrorCodeMutation,
  useGetErrorCodesQuery,
} from '@api/errorCodes.api';

import ModelsMultiSelector from '@components/ModelsMultiSelector';
import SearchBar from '@components/SearchBar';
import UpsertErrorCodeForm, {
  UPSERT_ERROR_CODE_FORM_MODAL_ID,
} from '@components/UpsertErrorCodeForm';
import { LayoutContextType } from '@components/layout/Layout';

export default function ErrorCodes() {
  // ==========================================================================
  // State
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<GetErrorCodesRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 10),
    searchQuery: searchParams.get('search') || undefined,
    sortBy: 'code',
    sortOrder: 'asc',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<ErrorCode>>({
    columnAccessor: 'code',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as GetErrorCodesSortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: errorCodesCount = { count: 0 }, isLoading: isLoadingCount } =
    useCountErrorCodesQuery({ searchQuery, models: filters.models });

  const { data = [], isLoading } = useGetErrorCodesQuery({
    ...filters,
    searchQuery,
  });

  const [deleteErrorCode] = useDeleteErrorCodeMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateErrorCodeClick = () => {
    openModal({
      modalId: UPSERT_ERROR_CODE_FORM_MODAL_ID,
      size: 'lg',
      title: 'Crea codice di errore',
      children: <UpsertErrorCodeForm />,
    });
  };

  const handleEditErrorCodeClick = (errorCode: ErrorCode) => {
    openModal({
      modalId: UPSERT_ERROR_CODE_FORM_MODAL_ID,
      size: 'lg',
      title: 'Modifica codice di errore',
      children: <UpsertErrorCodeForm errorCode={errorCode} />,
    });
  };

  const handleDeleteErrorCodeClick = (name: string) => {
    openConfirmModal({
      title: 'Elimina codice di errore',
      children: 'Stai per eliminare il codice di errore.\nContinuare?',
      confirmProps: { color: 'red' },
      labels: { confirm: 'Conferma', cancel: 'Annulla' },
      onConfirm: async () => {
        await deleteErrorCode(name).unwrap();

        showNotification({
          title: 'Codice di errore eliminato',
          message: 'Codice di errore eliminato con successo',
        });
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useEffect(() => {
    setLayoutProps({ title: 'Codici di errore' });
  }, [setLayoutProps]);

  return (
    <>
      <Group mb="lg" justify="space-between">
        <Group>
          <SearchBar
            placeholder="Ricerca per codice"
            value={filters.searchQuery}
            onChange={(value) => setFilters({ ...filters, searchQuery: value })}
          />
          <ModelsMultiSelector
            variant="filled"
            w={{ base: '100%', md: 'auto' }}
            placeholder="Filtra per modelli associati"
            value={filters.models}
            onChange={(models) => setFilters((f) => ({ ...f, models }))}
          />
        </Group>
        <Button onClick={handleCreateErrorCodeClick} leftSection={<IconPlus />}>
          Crea codice di errore
        </Button>
      </Group>
      <DataTable
        /* General */
        striped
        noRecordsText="Nessun codice di errore trovato"
        /* Data */
        fetching={isLoading || isLoadingCount}
        idAccessor="code"
        columns={[
          {
            accessor: 'code',
            title: 'Codice',
            sortable: true,
          },
          {
            accessor: 'description',
            title: 'Descrizione',
          },
          {
            accessor: 'cause',
            title: 'Causa',
          },
          {
            accessor: 'solution',
            title: 'Soluzione',
          },
          {
            accessor: 'repairable',
            title: 'Riparabile',
            width: '0%',
            render: (record) => (
              <Stack align="center">
                {record.repairable ? (
                  <Tooltip label="Sì">
                    <IconCircleCheck color="green" />
                  </Tooltip>
                ) : (
                  <Tooltip label="No">
                    <IconCircleX color="red" />
                  </Tooltip>
                )}
              </Stack>
            ),
          },
          {
            accessor: 'models',
            title: 'Modelli',
            width: '0%',
            render: (record) => (
              <Stack align="center">
                <Tooltip
                  multiline
                  maw="30rem"
                  label={
                    record.models.length > 0
                      ? record.models.join(', ')
                      : 'Nessun modello associato'
                  }
                >
                  <IconInfoSquareRounded />
                </Tooltip>
              </Stack>
            ),
          },
          {
            accessor: 'actions',
            title: '',
            textAlign: 'right',
            width: '0%',
            render: (record) => (
              <Group wrap="nowrap" gap="xs">
                <Tooltip label="Modifica codice di errore">
                  <ActionIcon
                    variant="light"
                    onClick={() => handleEditErrorCodeClick(record)}
                  >
                    <IconPencil />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Elimina codice di errore">
                  <ActionIcon
                    color="red"
                    variant="light"
                    onClick={() => handleDeleteErrorCodeClick(record.code)}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        records={data}
        /* Sorting */
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        /* Pagination */
        page={filters.page!}
        recordsPerPage={filters.pageLength!}
        totalRecords={errorCodesCount.count}
        paginationText={() => ''}
        recordsPerPageLabel=""
        recordsPerPageOptions={[10, 20, 50]}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onRecordsPerPageChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
