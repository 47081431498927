import {
  CountResponse,
  PaginationQueryParams,
  SearchQueryParams,
  SortQueryParams,
} from '@interfaces/common.interface';

import api from './';

export type GetBrandsSortBy = 'name';

export type CountBrandsRequestParams = Partial<SearchQueryParams>;

export interface Brand {
  name: string;
}

export interface CreateBrandRequestBody {
  name: string;
}

export type UpdateBrandRequestBody = CreateBrandRequestBody;

export interface UpdateBrandRequest {
  name: string;
  body: UpdateBrandRequestBody;
}

export interface GetBrandsRequestParams
  extends CountBrandsRequestParams,
    Partial<PaginationQueryParams>,
    Partial<SortQueryParams<GetBrandsSortBy>> {}

export const {
  useCountBrandsQuery,
  useGetBrandsQuery,
  useCreateBrandMutation,
  useUpdateBrandMutation,
  useDeleteBrandMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    countBrands: build.query<CountResponse, CountBrandsRequestParams>({
      query: (params) => ({
        url: 'brands/count',
        params,
      }),
      providesTags: ['brands'],
    }),
    getBrands: build.query<Brand[], GetBrandsRequestParams>({
      query: (params) => ({
        url: 'brands',
        params,
      }),
      providesTags: ['brands'],
    }),
    createBrand: build.mutation<Brand, CreateBrandRequestBody>({
      query: (body) => ({
        url: 'brands',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['brands'],
    }),
    updateBrand: build.mutation<Brand, UpdateBrandRequest>({
      query: ({ name, body }) => ({
        url: `brands/${name}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['brands'],
    }),
    deleteBrand: build.mutation<Brand, string>({
      query: (name) => ({
        url: `brands/${name}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['brands'],
    }),
  }),
});
