import {
  CountResponse,
  PaginationQueryParams,
  SearchQueryParams,
  SortQueryParams,
} from '@interfaces/common.interface';

import api from './';

export type GetErrorCodesSortBy = 'code';

export interface CountErrorCodesRequestParams
  extends Partial<SearchQueryParams> {
  models?: string[];
}

export interface ErrorCode {
  code: string;
  description: string;
  cause: string;
  solution: string | null;
  repairable: boolean;
  models: string[];
}

export interface CreateErrorCodeRequestBody {
  code: string;
  description: string;
  cause: string;
  solution: string | null;
  repairable: boolean;
  models: string[];
}

export type UpdateErrorCodeRequestBody = CreateErrorCodeRequestBody;

export interface UpdateErrorCodeRequest {
  code: string;
  body: UpdateErrorCodeRequestBody;
}

export interface GetErrorCodesRequestParams
  extends CountErrorCodesRequestParams,
    Partial<PaginationQueryParams>,
    Partial<SortQueryParams<GetErrorCodesSortBy>> {}

export const {
  useCountErrorCodesQuery,
  useGetErrorCodesQuery,
  useCreateErrorCodeMutation,
  useUpdateErrorCodeMutation,
  useDeleteErrorCodeMutation,
} = api.injectEndpoints({
  endpoints: (build) => ({
    countErrorCodes: build.query<CountResponse, CountErrorCodesRequestParams>({
      query: (params) => ({
        url: 'error-codes/count',
        params,
      }),
      providesTags: ['models/errorCodes'],
    }),
    getErrorCodes: build.query<ErrorCode[], GetErrorCodesRequestParams>({
      query: (params) => ({
        url: 'error-codes',
        params,
      }),
      providesTags: ['models/errorCodes'],
    }),
    createErrorCode: build.mutation<ErrorCode, CreateErrorCodeRequestBody>({
      query: (body) => ({
        url: 'error-codes',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['models/errorCodes'],
    }),
    updateErrorCode: build.mutation<ErrorCode, UpdateErrorCodeRequest>({
      query: ({ code, body }) => ({
        url: `error-codes/${code}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['models/errorCodes'],
    }),
    deleteErrorCode: build.mutation<ErrorCode, string>({
      query: (code) => ({
        url: `error-codes/${code}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['models/errorCodes'],
    }),
  }),
});
