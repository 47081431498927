import { DataTable, DataTableSortStatus } from 'mantine-datatable';
import { useEffect, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';

import { ActionIcon, Button, Group, Stack, Tooltip } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { openConfirmModal, openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import {
  IconInfoSquareRounded,
  IconPencil,
  IconPlus,
  IconTrash,
  IconX,
} from '@tabler/icons-react';

import { isApiError } from '@api/index';
import {
  GetModelsRequestParams,
  GetModelsSortBy,
  Model,
  useCountModelsQuery,
  useDeleteModelMutation,
  useGetModelsQuery,
} from '@api/models.api';

import BrandsSelector from '@components/BrandsSelector';
import ErroCodesMultiSelector from '@components/ErrorCodesMultiSelector';
import SearchBar from '@components/SearchBar';
import UpsertModelForm, {
  UPSERT_MODEL_FORM_MODAL_ID,
} from '@components/UpsertModelForm';
import { LayoutContextType } from '@components/layout/Layout';

export default function Models() {
  // ==========================================================================
  // State
  // ==========================================================================
  const [searchParams] = useSearchParams();

  const [filters, setFilters] = useState<GetModelsRequestParams>({
    page: +(searchParams.get('page') || 1),
    pageLength: +(searchParams.get('pageLength') || 10),
    searchQuery: searchParams.get('search') || undefined,
    sortBy: 'name',
    sortOrder: 'asc',
  });

  const [searchQuery] = useDebouncedValue(filters.searchQuery, 200, {
    leading: true,
  });

  const [sortStatus, setSortStatus] = useState<DataTableSortStatus<Model>>({
    columnAccessor: 'name',
    direction: 'asc',
  });

  useEffect(() => {
    if (sortStatus) {
      setFilters((f) => ({
        ...f,
        sortBy: sortStatus.columnAccessor as GetModelsSortBy,
        sortOrder: sortStatus.direction,
      }));
    }
  }, [sortStatus]);

  // ==========================================================================
  // Api
  // ==========================================================================
  const { data: modelsCount = { count: 0 }, isLoading: isLoadingCount } =
    useCountModelsQuery({ searchQuery, errorCodes: filters.errorCodes });

  const { data = [], isLoading } = useGetModelsQuery({
    ...filters,
    searchQuery,
  });

  const [deleteModel] = useDeleteModelMutation();

  // ==========================================================================
  // Handlers
  // ==========================================================================
  const handleCreateModelClick = () => {
    openModal({
      modalId: UPSERT_MODEL_FORM_MODAL_ID,
      title: 'Crea modello',
      children: <UpsertModelForm />,
    });
  };

  const handleEditModelClick = (model: Model) => {
    openModal({
      modalId: UPSERT_MODEL_FORM_MODAL_ID,
      title: 'Modifica modello',
      children: <UpsertModelForm model={model} />,
    });
  };

  const handleDeleteModelClick = (name: string) => {
    openConfirmModal({
      title: 'Elimina modello',
      children: 'Stai per eliminare il modello.\nContinuare?',
      confirmProps: { color: 'red' },
      labels: { confirm: 'Conferma', cancel: 'Annulla' },
      onConfirm: async () => {
        try {
          await deleteModel(name).unwrap();

          showNotification({
            title: 'Modello eliminato',
            message: 'Modello eliminato con successo',
          });
        } catch (e) {
          if (isApiError(e)) {
            showNotification({
              title: 'Errore',
              message: e.data.message,
              color: 'red',
              icon: <IconX />,
            });
          } else {
            showNotification({
              title: 'Errore',
              message: 'Errore sconosciuto',
              color: 'red',
              icon: <IconX />,
            });
            console.error(e);
          }
        }
      },
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================
  const { setLayoutProps } = useOutletContext<LayoutContextType>();

  useEffect(() => {
    setLayoutProps({ title: 'Modelli' });
  }, [setLayoutProps]);

  return (
    <>
      <Group mb="lg" justify="space-between">
        <Group>
          <SearchBar
            placeholder="Ricerca per nome"
            value={filters.searchQuery}
            onChange={(value) => setFilters({ ...filters, searchQuery: value })}
          />
          <BrandsSelector
            variant="filled"
            w={{ base: '100%', md: 'auto' }}
            placeholder="Filtra per marca"
            value={filters.brandName || null}
            onChange={(brandName) =>
              setFilters((f) => ({
                ...f,
                brandName: brandName || undefined,
              }))
            }
          />
          <ErroCodesMultiSelector
            variant="filled"
            w={{ base: '100%', md: 'auto' }}
            maw="30rem"
            placeholder="Filtra per codici di errore"
            value={filters.errorCodes}
            onChange={(errorCodes) => setFilters((f) => ({ ...f, errorCodes }))}
          />
        </Group>
        <Button onClick={handleCreateModelClick} leftSection={<IconPlus />}>
          Crea modello
        </Button>
      </Group>
      <DataTable
        /* General */
        striped
        noRecordsText="Nessun modello trovato"
        /* Data */
        fetching={isLoading || isLoadingCount}
        idAccessor="name"
        columns={[
          {
            accessor: 'name',
            title: 'Nome',
            sortable: true,
          },
          {
            accessor: 'brandName',
            title: 'Marca',
            sortable: true,
          },
          {
            accessor: 'errorCodes',
            title: 'Codici di errore',
            width: '0%',
            render: (record) => (
              <Stack align="center">
                <Tooltip
                  multiline
                  maw="30rem"
                  label={
                    record.errorCodes.length > 0
                      ? record.errorCodes.join(', ')
                      : 'Nessun codice di errore associato'
                  }
                >
                  <IconInfoSquareRounded />
                </Tooltip>
              </Stack>
            ),
          },
          {
            accessor: 'actions',
            title: '',
            textAlign: 'right',
            width: '0%',
            render: (record) => (
              <Group wrap="nowrap" gap="xs">
                <Tooltip label="Modifica modello">
                  <ActionIcon
                    variant="light"
                    onClick={() => handleEditModelClick(record)}
                  >
                    <IconPencil />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Elimina modello">
                  <ActionIcon
                    color="red"
                    variant="light"
                    onClick={() => handleDeleteModelClick(record.name)}
                  >
                    <IconTrash />
                  </ActionIcon>
                </Tooltip>
              </Group>
            ),
          },
        ]}
        records={data}
        /* Sorting */
        sortStatus={sortStatus}
        onSortStatusChange={setSortStatus}
        /* Pagination */
        page={filters.page!}
        recordsPerPage={filters.pageLength!}
        totalRecords={modelsCount.count}
        paginationText={() => ''}
        recordsPerPageLabel=""
        recordsPerPageOptions={[10, 20, 50]}
        onPageChange={(newPage) => setFilters({ ...filters, page: newPage })}
        onRecordsPerPageChange={(newPageLength) =>
          setFilters({ ...filters, pageLength: newPageLength, page: 1 })
        }
      />
    </>
  );
}
